.navbar {
    height: 250px;
    margin: 0 0 15rem 0;
    display: flex;
    flex-direction: column;
}

.banner {
    width: 100%;
    height: 80px;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../images/RustTexture_smallV1.jpg');
    background-size: 100px;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 50;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.428);
}
  
  .gm-badge {
    text-decoration: none;
    font-family: 'Ibarra Real Nova';
    letter-spacing: 0.1em;
    font-size: 1rem;
    background-color: var(--main-cream);
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 65px;
    color: var(--main-black);
    box-shadow: 
      inset 0 0 0 5px var(--main-cream),
      inset 0 0 0 7px var(--main-black);
}

.gm-badge:hover {
    text-decoration: underline;
}

.navbar-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2em;
    width: 100%;
    height: 550px;
    background-image: url('../../images/RustTexture_smallV1.jpg');
    background-size: 100px;
    transition: all 0.5s linear;
    position: fixed;
    z-index: 10;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.428);
}

.navbar-list li {
    list-style: none;
}

.navLink {
    border-bottom: 2px solid var(--main-cream);
}

.nav-btn {
    background-color: transparent;
    padding: 1em;
    border: none;
    color: var(--main-cream);
    font-family: 'Cormorant SC';
    font-size: 1.5rem;
    font-weight: bold;
}

.nav-btn:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--main-cream);
}

#navMenu {
    position: fixed;
    width: 50px;
    z-index: 100;
    background-color: transparent;
    margin: 0 15px;
    right: 0;
}
#navMenu > span {
    display: block;
    width: 28px;
    height: 3px;
    border-radius: 9999px;
    background-color: var(--main-cream);
    z-index: 1000;
}
#navMenu > span:not(:last-child) {
    margin-bottom: 7px;
}
#navMenu, #navMenu > span {
    transition: all .2s linear;
}
#navMenu.active {
    transition-delay: 0.1s;
    transform: rotate(45deg);
}
#navMenu.active > span:nth-child(2) {
    width: 0;
}
#navMenu.active > span:nth-child(1), 
#navMenu.active > span:nth-child(3) {
    transition-delay: .2s;
}
#navMenu.active > span:nth-child(1) {
    transform: translateY(10px);
}
#navMenu.active > span:nth-child(3) {
    transform: translateY(-10px) rotate(90deg);
}

@media only screen and (min-width: 1000px) {
    .gm-badge {
        display: none;
    }
    .banner {
        height: 100px;
    }
    .navbar {
        height: fit-content
    }
    .navbar-list {
        flex-direction: row;
        height: 100px;
        background-color: transparent;
        /* font-size: 2rem; */
        z-index: 1000;
        padding: 0;
    }
    .navbar-list.inactive {
        transform: translateY(0);
    }
    #navMenu {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .navbar {
        height: 50px;
        justify-content: space-between;
        align-items: center;
    }
    .banner {
        justify-content: space-between;
        
    }
    #navMenu {
        margin: 0 0 0 0;
    }
    .gm-badge {
        height: 50px;
        width: 50px;
        /* font-size: 1rem; */
        position: fixed;
    }
    .navbar-list.active {
        transform: translateY(50px);
    }
    
    .navbar-list.inactive {
        transform: translateY(-760px);
    }
}