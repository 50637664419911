.directions-section {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    line-height: 1.5em;
    font-size: 1.75rem;
    color: var(--main-black);
    font-family: 'Ibarra Real Nova';
    gap: 0.5em;
}
#directions-title {
    margin: 4rem 0;
    padding-bottom: 1rem;
    grid-area: title;
    align-self: center;
    font-size: 4rem;
    width: fit-content;
}
.ceremony-title, .reception-title {
    padding: 0.5em;
    margin: 5px;
}
.directions-section h2, .directions-section h3 {
    border-bottom: 1px solid var(--main-rust);
}
.directions-section-subtext {
    font-family: Raleway;
}

@media only screen and (min-width: 1000px) {
    .directions-section {
        /* font-size: 2em; */
    }
}