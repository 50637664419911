* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.3em;
  /* border: 1px solid gray; */
}

:root {
  --main-rust: #873F2A;
  --main-cream: #EEE2D2;
  --light-rust: #B94310;
  --light-grey: #807E7C;
  --dark-grey: #555656;
  --main-black: #312d2b;
}

.App {
  text-align: center;
  width: 100vw;
  max-width: 100%;
  height: auto;
  padding: 0 0 5em 0;
  background-image: url('./images/RustTexture_smallV1.jpg');
  background-size: 150px;
  /* background-repeat:space; */
  background-color: var(--main-rust);
  color: var(--main-black);
}

.main-container {
  width: 90%;
  max-width: 800px;
  /* min-height: 450vh;
  max-height: fit-content; */
  height: fit-content;
  margin: 0 auto;
  margin-top: 4em;
  background-image: url('../src/images/cream_texture_small.jpg');
  background-size: 150px;
  display: flex;
  justify-content: center;
  padding-top: 15em;
  /* padding-bottom: 200px; */
  border-radius: 1000px;
}

@media only screen and (max-width: 1200px) {
  .main-container {
    padding-top: 10em;
  }
  .home-page {
    height: 90%;
  }
}

@media only screen and (max-width: 1100px) {
  .flower {
    margin-top: 0;
  }
  .main-container {
    min-height: 0;
  }
}

@media only screen and (max-width: 700px) {
  .main-container {
    background-size: 90%;
    padding-top: 5em;
    height: fit-content;
    min-height: fit-content;
  }
}

@media only screen and (max-width: 300px) {
  .main-container {
    padding-top: 2em;
  }
}

@media only screen and (min-width: 1000px) {
  .App, .main-container {
    background-size: 250px;
  }
  .main-container {
    margin-top: 10em;
  }
}
