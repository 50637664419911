.faq-section {
    width: 70%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    gap: 3em;
    color: var(--main-black);
    font-family: 'Ibarra Real Nova';
    font-size: 1.75rem;
}
#faq-title {
    grid-area: title;
    align-self: center;
    font-size: 4rem;
    width: fit-content;
    margin: 4rem 0;
}
#faq-title, .faq-section h3 {
    border-bottom: 1px solid var(--main-rust);
}

.question {
    width: fit-content;
}
