.main-div {
    height: fit-content;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
    margin-bottom: 5em;
    font-size: 1.75rem;
}


.hero-section {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    font-family: 'Cormorant SC';
    color: var(--main-black);
    width: 80%;
}

#hero-title {
    font-size: 4rem;
}
.hero-section h2 {
    font-size: 1.75rem;
}

.home-flower {
    width: 90%;
}

.rsvp {
    border: 1px solid var(--main-rust);
    border-radius: 20px;
    padding: 0.5em;
    text-decoration: none;
    color: var(--main-rust);
    margin-top: 1em;
}

.rsvp:hover, .rsvp:active {
    cursor: pointer;
    background-color: var(--main-rust);
    color: var(--main-cream);
}

.the-wedding-section {
    display: none;
    grid-template-columns: 1fr 10px 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas: 
                    "title title title"
                    "when empty where"
                    "button button button"
                    "reception reception reception";
    text-align: center;
    justify-content: center;
    gap: 1em;
    margin: 20%;
    line-height: 1.5em;
    font-family: 'Ibarra Real Nova';
    color: var(--main-black);
}
#wedding-title {
    grid-area: title;
    align-self: center;
}
#when {
    grid-area: when;
}
#where {
    grid-area: where;
}
#reception {
    grid-area: reception;
}
#wedding-title, #when, #where, #reception {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7em;
}
.the-wedding-section h2, .the-wedding-section h3 {
    width: fit-content;
    border-bottom: 1px solid var(--main-rust);
}
.the-wedding-section h3 {
    margin: 1em 0;
}
.the-wedding-section h5 {
    margin-bottom: -0.5em;
}

.the-wedding-section {
    margin: 5% 15%;
    grid-template-columns: 1fr;
    grid-template-areas: 
                    "title"
                    "when"
                    "where"
                    "reception";
    justify-content: center;
}


@media only screen and (min-width: 750px) {
    .the-wedding-section {
        display: grid;
    }
    #hero-title {
        font-size: 5rem !important;
        margin: 0;
        padding: 0;
    }
    .main-div {
        margin-top: 2rem;
        font-size: 2.5rem;
    }
}