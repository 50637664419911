@import "~react-image-gallery/styles/css/image-gallery.css";

.our-story-section {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    color: var(--main-black);
    font-family: 'Ibarra Real Nova';
    font-size: 0.9em;
    align-items: center;
    overflow-x: hidden;
}

#our-story-title {
    grid-area: title;
    font-size: 4rem;
    text-align: center;
    margin: 4rem 0;
}
.hero-image {
    grid-area: image;
    align-self: center;
    width: 100%;
    max-width: 800px;
    height: auto;
    border: 20px solid white;
    /* border-bottom: 60px solid white; */

}

.os-flower.one {
    margin: 0;
}

.cream-rip-transition-down {
    margin-top: 10px;
    width: 200%;
    z-index: 1;
}
.cream-rip-transition-up {
    transform: rotate(180deg);
    margin-top: 10px;
    width: 200%;
    z-index: 1;
    margin-top: -40px;
}

.image-gallery-react {
    grid-area: image;
    align-self: center;
    width: 100%;
    height: auto;
    margin-top: -30px;
}

figcaption {
    display: none;
    /* margin-top: -60px; */
    margin-bottom: 65px;
    line-height: 1em;
    font-size: 0.7em;
}
#our-story-paragraph {
    margin: 0 auto;
    grid-area: story;
    align-self: center;
    width: 90%;
    font-size: 2rem;
    line-height: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.os-flower {
    width: 50%;
    max-width: 300px;
    display: block;
    margin: 5em 0;
}

@media only screen and (min-width: 700px) {
    #our-story-paragraph {
        font-size: 2em;
    }
    .cream-rip-transition-down, .cream-rip-transition-up {
        width: 100%;
    }
    .image-gallery-react {
        margin-top:-45px;
    }
    .os-flower.one {
        margin: 3em;
    }
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30em;
    gap: 10px;
}

.gallery-img:first-of-type {
    width: 100% !important;
}

.image-gallery-left-nav > svg > polyline {
    height: 10px;
}