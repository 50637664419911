.details-section {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 1em;
    font-size: 1.75rem;
    line-height: 1.5em;
    color: var(--main-black);
    font-family: 'Ibarra Real Nova';
}
#details-title {
    grid-area: title;
    align-self: center;
    font-size: 4rem;
    width: fit-content;
    margin: 4rem 0;
}
.details-section h3 {
    font-size: 2.3rem;
}
#when {
    grid-area: when;
    margin-top: 20px;
}
#where {
    grid-area: where;
}
#reception {
    grid-area: reception;
}
.det-flower {
    width: 80%;
    max-width: 400px;
    grid-area: flower;
    align-self: center;
    margin: 5em 0;
}
#details-title, .details-section h3, .reception-to-follow {
    border-bottom: 1px solid var(--main-rust);
}

.details-section-subtext {
    font-family: Raleway;
    /* font-size: 0.7em; */
}


